<template>
  <div class="container" v-title :data-title="$t('i18n.userManagement')">
    <div id="outer-title">{{ $t("i18n.userManagement") }}</div>
    <div v-loading="loading">
      <jl-table
        :tableData="tableData"
        @init="init"
        :options="options"
        :columns="columns"
        :operates="operates"
        :total="total"
        :height="$defined.HEIGHT - 360 + ''"
      >
      </jl-table>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import jlTable from "../../components/table";
export default {
  name: "Authorization",
  components: {
    jlTable,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const { t } = useI18n();
    const router = useRouter();
    const state = reactive({
      formInline: {
        page: 1,
        pageSize: 10,
      },
      // table数据
      tableData: [],
      options: {
        // table 的参数
        isAdd: false, // 是否启用新增功能
        isEdit: false, // 是否启用编辑功能
        highlightCurrentRow: false, // 是否支持当前行高亮显示
        multiSelect: false, // 是否支持列表项选中功能
        pageShow: true, // 是否翻页
      },
      columns: [
        // 需要展示的列
        {
          prop: "userName",
          label: "userName",
          align: "center",
          search: true,
          type: "input",
          rules: [{ required: true, message: t("i18n.input") }],
          formatter: (row) => {
            if (proxy.$defined.btnPermission("编辑用户")) {
              return `<a>${row.userName}</a>`;
            } else {
              return `<span>${row.userName}</span>`;
            }
          },
          method: (row) => {
            if (proxy.$defined.btnPermission("编辑用户")) {
              router.push({
                path: "/user/addEditAuthorization",
                query: { flag: 1, id: row.id },
              });
            }
          },
        },
        {
          prop: "nick",
          label: "nick",
          search: true,
          type: "input",
          align: "center",
        },
        {
          prop: "allotName",
          label: "group",
          align: "left",
          width: 350,
        },
        {
          prop: "roleName",
          label: "role",
          align: "center",
        },
        {
          prop: "inUse",
          label: "status",
          align: "center",
          search: true,
          type: "select",
          props: { label: "locale", value: "id" },
          data: [
            { id: "0", locale: "forbid" },
            { id: "1", locale: "inUse" },
          ],
          width: 80,
          formatter: (row) => {
            if (row.inUse == 1) {
              return `<span style="color: #67c23a">${t("i18n.inUse")}</span>`;
            } else {
              return `<span>${t("i18n.forbid")}</span>`;
            }
          },
        },
        {
          label: "operation",
          align: "center",
          rows: proxy.$defined.btnPermission("禁用用户"),
          formatter: (row) => {
            let text = row.inUse == 1 ? "forbid" : "enable";
            return `<a><i class="el-icon-delete"></i>&nbsp;
              ${t("i18n." + text)}</a>`;
          },
          method: (row, formInline) => {
            handleDel(row, formInline);
          },
        },
      ],
      operates: {
        // 列操作按钮
        width: 200,
        fixed: "right",
        list: [
          {
            id: "1",
            label: "new",
            icon: "el-icon-plus",
            show: proxy.$defined.btnPermission("添加用户"),
            type: "outer",
            class: "add",
            method: () => {
              router.push({
                path: "/user/addEditAuthorization",
                query: { flag: 0 },
              });
            },
          },
        ],
      },
      total: 0,
      loading: true,
    });

    const init = async (params) => {
      state.loading = true;
      params.nickName = params.nick || "";
      const { data } = await proxy.$api.system.userPage(params);
      data.records.map((item) => {
        if (item.roleDTOList.length > 0) {
          item.roleName = item.roleDTOList[0].name;
        }
      });
      state.tableData = data.records;
      state.total = data.total;
      state.loading = false;
    };

    const handleDel = (row, formInline) => {
      // 禁用/解禁
      var tag =
        row.inUse == 1
          ? "是否继续禁用该用户？"
          : "该用户已经被禁用，是否解禁？";
      const tips = row.inUse == 1 ? "forbid" : "inUse";

      let callback = () => {
        proxy.$api.system.delUser({ id: row.id }).then(() => {
          init(formInline);
          proxy.$defined.tip(t("i18n." + tips) + t("i18n.success"), "success");
        });
      };
      proxy.$defined.confirm(callback, tag, t("i18n.edit"));
    };

    return {
      ...toRefs(state),
      init,
    };
  },
};
</script>
